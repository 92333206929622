.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.project-container{
    max-width: 100%;
    margin: auto;
    display: flex;
    grid-gap: 40px;
    padding-bottom: 5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.project-card{
    max-width: 532px;
    background: #1a1919;
    padding: 1.2rem 1rem;
}

.project-card .img1{
    width: 100%;
}

.project-tittle{
    color: #fff;
    padding: 1rem 0;
    text-align: justify;
}

.pro-datails p{
    padding-bottom: 1rem;
    font-size: 1.1rem;   
    line-height: 1.6;
    
}

@media screen and (max-width: 740px){

    .project-container{
        max-width: 95%;
        margin: auto;
    }

    .project-card{
        padding: 1.2rem 1rem 5rem ;
    }

    .img1{
        max-height: 200px;
    }

    .project-tittle{
        font-size: 20px;
    }
}