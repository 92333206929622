.pricing{
    width: 100%;
    padding: 6rem 1rem;
    background: #000;
}

.card-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.card{
    width: 20%;
    border: 1px solid #eee;
    color: #eee;
    text-align: center;
    padding: 2rem 0 0 0;
    margin: 20px;
}

.card h3{
    font-size: 1.4rem;
    padding: 1rem;
}

.card p{
    padding: 10px 0;
}

.bar{
    border-bottom: 1px solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}

.btc{
    font-size: 4rem;
    font-weight: 600;
    padding: 1rem 0;
}


.card:hover{
    background-color: #141414;
}

.teste{
    margin-bottom: 25%;
}


@media screen and (max-width: 740px) {

    .card-container{
        max-width: 100%;
        margin: 0;
        flex-direction: column;
        align-items: center;
    }

    .pricing{
        display: flex;
        align-items: center;
    }

    .card{
        width: 90%;
    }

    .card h3{
        font-size: 1.5rem;
        padding: 1rem;
    }
    
    .card p{
        padding: 3px 0;
    }


    .btc{
        font-size: 3rem;
        font-weight: 600;
        padding: 1rem 0;
    }
    
}