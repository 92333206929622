.about{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textAbout h1{
    font-size: 3rem;
    padding: 0.6rem 0 1.5rem;
}

.textAbout p{
    font-size: 1rem;
    padding: 0.6rem 0 1.5rem;
    text-align: left;
    margin-bottom: 30px;
}

.textAbout{
    width: 40%;
}

.gifAbout{
    width: 500px;
}

.barAbout{
    border-bottom: 1px solid #eee;
    width: 90%;
    margin: 1.5rem auto;
    display: block;
}

@media screen and (max-width: 1591px) {
    .about{
        height: auto;
        flex-direction: column;
    }

    .gifAbout{
        width: 300px;
        margin-top: 3%;
    }

    .textAbout{
        width: 90%;
    }
}