label{
    color: #fff;
}

.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    max-width: 600px;
}

.form input, .form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgb(255, 255, 255, 0.1);
    border-color: rgb(255, 255, 255, 0.1) ;
    color: #f4f4f4;
}

.gif{
    width: 500px;
}

.contatos{
    display: flex;
    justify-content: center;
}

.esquerda{
    display: flex;
    flex-direction: column;
    align-items: center;

}

@media screen and (max-width: 700px) {
    .contatos{
        flex-direction:column-reverse;
        align-items: center;

    }

    .gif{
        width: 100%;
    }

    .esquerda{
        flex-direction: column-reverse;
    }

}

@media screen and (max-width: 800px) and (min-width: 700px) {
    .gif{
        width: 400px;
    }
}