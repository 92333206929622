.heading{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video{
    width: 100%;
}

img {
    width: 100%;
}

.text{
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.text h1{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.text p{
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
}

@media screen and (max-width:640px) {
    
    .text h1{
        font-size: 2.5rem;
    }
    
    .text p{
        font-size: 1.8rem;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit:cover;
    }

    .heading{
        height: 500px;
    }

}