@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Source Code Pro", monospace;
}

body {
  background: #000000;
}

h1,
h4,
p,
a {
  color: #ffffff;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(104, 0, 0);
  color: #ffffff;
  border: 0.3px solid #ffffff7c;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 0.3px solid #ffffff7c;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

.btn-light:hover{
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}